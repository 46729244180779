import React from "react";
import Moment from "react-moment";

const GigItem = (props) => {
  console.log(props);
  return (
    <div className="gig-item">
      <h3 className="gig-datetime">
        <Moment format="LLL">{props.date}</Moment>
      </h3>
      <p className="gig-venue">{props.venue}</p>
      <p className="gig-address">{props.address}</p>
    </div>
  );
};

export default GigItem;
