import React from "react";

const MenuButton = (props) => {
  return (
    <button className="menu" onClick={props.onClick}>
      MENU
    </button>
  );
};

export default MenuButton;
