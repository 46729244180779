import React from "react";
import Nav from "./Nav";
import Record from "./Record";

const Hero = () => {
  return (
    <div className="hero">
      <Nav />
      <div className="web-title">
        <div className="left-spacer">
          <div></div>
        </div>
        <h1>
          CRYSTAL <br /> PALACE
        </h1>
        <div className="right-spacer">
          <div></div>
        </div>
      </div>
      <h2>VINYL SOUND SELECTOR</h2>
      <Record />
    </div>
  );
};

export default Hero;
