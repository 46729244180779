import { React, useState } from "react";
import MenuButton from "./MenuButton";

const Nav = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    menuOpen ? setMenuOpen(false) : setMenuOpen(true);
    console.log("click");
  };
  return (
    <nav>
      <ul className="nav-left">
        <li>crystal palace</li>
      </ul>
      <div className="nav-right">
        <MenuButton onClick={toggleMenu} />
        {menuOpen && (
          <ul class="column-menu">
            <li>
              <a
                href="https://soundcloud.com/chris-burek"
                target="_blank"
                rel="noreferrer"
              >
                listen
              </a>
            </li>
            <li>
              <a href="#gigs">live</a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/chrisburek/"
                target="_blank"
                rel="noreferrer"
              >
                social
              </a>
            </li>
          </ul>
        )}
        <ul className="inline-menu">
          <li>
            <a
              href="https://soundcloud.com/chris-burek"
              target="_blank"
              rel="noreferrer"
            >
              listen
            </a>
          </li>
          <li>
            <a href="#gigs">live</a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/chrisburek/"
              target="_blank"
              rel="noreferrer"
            >
              social
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Nav;
