import React, { useState, useEffect } from "react";
import GigItem from "./GigItem";

const contentful = require("contentful");

const client = contentful.createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE,
  environment: "master", // defaults to 'master' if not set
  accessToken: process.env.REACT_APP_CONTENTFUL_TOKEN,
});

const promise = client
  .getEntries()
  .then((response) => response.items)
  .catch(console.error);

const GigList = () => {
  let [gigArray, setGigs] = useState([]);
  let [pastGigs, setPastGigs] = useState([]);
  let [showAllPrevious, setShowAllPrevious] = useState(false);
  let [futureGigs, setFutureGigs] = useState([]);

  useEffect(() => {
    promise.then((gigs) => {
      // Get current date
      const currentDate = new Date();

      // Separate gigs into past and future
      const pastGigsArray = gigs
        .filter((gig) => new Date(gig.fields.dateAndTime) < currentDate)
        .sort(
          (a, b) =>
            new Date(b.fields.dateAndTime) - new Date(a.fields.dateAndTime)
        );

      const futureGigsArray = gigs
        .filter((gig) => new Date(gig.fields.dateAndTime) >= currentDate)
        .sort(
          (a, b) =>
            new Date(a.fields.dateAndTime) - new Date(b.fields.dateAndTime)
        );

      setGigs(gigs);
      setPastGigs(pastGigsArray);
      setFutureGigs(futureGigsArray);
    });
  }, []);

  const toggleShowAllPrevious = () => {
    setShowAllPrevious(!showAllPrevious);
  };

  return (
    <div className="gig-list">
      {futureGigs.length > 0 && (
        <>
          <h3>Upcoming</h3>
          {futureGigs.map((gig, index) => (
            <GigItem
              key={index}
              venue={gig.fields.venue}
              address={gig.fields.address}
              date={gig.fields.dateAndTime}
            />
          ))}
        </>
      )}

      {pastGigs.length > 0 && (
        <>
          <h3>Previous</h3>
          {pastGigs
            .slice(0, showAllPrevious ? pastGigs.length : 1)
            .map((gig, index) => (
              <GigItem
                key={index}
                venue={gig.fields.venue}
                address={gig.fields.address}
                date={gig.fields.dateAndTime}
              />
            ))}
          {pastGigs.length > 1 && (
            <p className="gig-toggle" onClick={toggleShowAllPrevious}>
              {showAllPrevious ? "SEE LESS" : "SEE MORE"}
            </p>
          )}
        </>
      )}
    </div>
  );
};

export default GigList;
