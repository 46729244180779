import React from "react";
import GigList from "./GigList";

const Section = (props) => {
  return (
    <div id={props.id} className="section">
      <h2>GIGS</h2>
      <GigList />
    </div>
  );
};

export default Section;
